import { useEffect, useState } from "react";
import { BrowserRouter, HashRouter, useRoutes } from "react-router-dom";
import Routes from "./routes/Routes";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useAuth } from "./hooks/useAuth";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import auth from "./services/auth";
import { ThemeProvider } from "./components/theme-provider";
import { MissionContext } from "./hooks/useMission";
import { DialogProvider } from "./components/dialog-context";
// import { Web3ModalProvider } from "./components/wallet-context";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
const projectId = "c0973f2b6020eb8492c58e4115852c02";
import { Toaster } from "@/components/ui/toaster";

// 2. Set chains
const mainnet = {
	chainId: 1,
	name: "Ethereum",
	currency: "ETH",
	explorerUrl: "https://etherscan.io",
	rpcUrl: "https://cloudflare-eth.com",
};

// 3. Create a metadata object
const metadata = {
	name: "Supercharge",
	description: "Supercharge",
	url: "https://superchar.xyz.com", // origin must match your domain & subdomain
	icons: [
		"https://images.squarespace-cdn.com/content/v1/65b5c45d10fa0e6aff9b8d38/462fed56-3a6f-4e00-9a0c-c5b3584ea960/Group+6.png?format=1500w",
	],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
	/*Required*/
	metadata,

	/*Optional*/
	enableEIP6963: true, // true by default
	enableInjected: true, // true by default
	enableCoinbase: true, // true by default
	rpcUrl: "...", // used for the Coinbase SDK
	defaultChainId: 1, // used for the Coinbase SDK
});

// 5. Create a AppKit instance
createWeb3Modal({
	ethersConfig,
	chains: [mainnet],
	projectId,
	enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

function App() {
	const { user } = useAuth();

	useEffect(() => {
		const app = initializeApp(firebaseConfig);
	}, []);

	if (user) {
		auth.setAuthorizationToken(user?.sctoken);
	}

	let routing = useRoutes(Routes.getRoutes());

	const firebaseConfig = {
		apiKey: "AIzaSyDOfd7I2dzCqeILkMBx2HNk-Nuql7fPUxY",
		authDomain: "supercharge-32b48.firebaseapp.com",
		projectId: "supercharge-32b48",
		storageBucket: "supercharge-32b48.firebasestorage.app",
		messagingSenderId: "612475495350",
		appId: "1:612475495350:web:5b6a25833007ed22779717",
		measurementId: "G-F8DW78BDQJ",
	};

	return (
		<div className="flex items-center justify-center h-screen">
			<main className="w-full h-full">
				<Toaster />
				{/* <Web3ModalProvider> */}
				<DialogProvider>
					<ThemeProvider
						defaultTheme="system"
						storageKey="spc-ui-theme">
						<MissionContext.Provider value={{}}>
							{routing}
						</MissionContext.Provider>
						<Toaster />
					</ThemeProvider>
				</DialogProvider>
				{/* </Web3ModalProvider> */}
			</main>
		</div>
	);
}

export default App;
